import * as React from 'react';
import { ClickAwayListener, Tooltip, Typography, withStyles } from '@material-ui/core';
import IconInfo from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            cursor: 'help'
        },
        position: 'relative',

    }
});

interface Props {
    tooltipValue: string;
    withIcon?: boolean;
}

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 600,
        maxHeight: '90vh',
        marginTop: 'auto',
        marginBottom: 'auto',
        overflow: 'auto',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid black',
    },
}))(Tooltip);

const TableTooltip: React.FC<Props> = ({ tooltipValue, children, withIcon = true }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <HtmlTooltip
                className={classes.root}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                interactive={true}
                title={
                    <React.Fragment>
                        <Typography color="inherit" style={{whiteSpace: 'pre-line'}}>{tooltipValue}</Typography>
                    </React.Fragment>
                }
                arrow
            >
                <div onClick={(e) => handleTooltipOpen()}  style={{ position: 'relative', top: '10px', height: '100%', width: '100%'}}>
                    {withIcon && <IconInfo style={{ position: 'absolute', top: '-12px', right: '-2px', fontSize: 14, color: '#666666' }} />}
                    {children}
                </div>
            </HtmlTooltip>
        </ClickAwayListener>
    );
};

export default TableTooltip;
