// export const firebaseConfig = {
//     apiKey: "AIzaSyDgwmthpXUFsvJPcFYsMrzIT9JRY8jkxbU",
//     authDomain: "riester-profi-check-fcfe4.firebaseapp.com",
//     databaseURL: "https://riester-profi-check-fcfe4.firebaseio.com",
//     projectId: "riester-profi-check-fcfe4",
//     storageBucket: "riester-profi-check-fcfe4.appspot.com",
//     messagingSenderId: "39423961505",
//     appId: "1:39423961505:web:5b5cab171b09dc366303e0"
// };

// smmd firebase
export const firebaseConfig = {
  apiKey: "AIzaSyA3agW2DcosnJkv8mTVGBIn_Qlo2J7DZ4E",
  authDomain: "dfiav-fd18e.firebaseapp.com",
  databaseURL: "https://dfiav-fd18e-default-rtdb.europe-west1.firebasedatabase.app", // "https://dfiav-fd18e-dev.europe-west1.firebasedatabase.app", // 
  projectId: "dfiav-fd18e",
  storageBucket: "dfiav-fd18e.appspot.com",
  messagingSenderId: "314977144708",
  appId: "1:314977144708:web:37bf2a9a719f0f3a2b57d1",
  measurementId: "G-3WYYZMHCYC"
};

// console.log(process.env.REACT_APP_ENV);

export const appVersion = '3.1.6';

/**
 * The duration we poll changes from the remote database
 * @type {number}
 */
// 5 minutes
export const pullProfileChangesDuration = 30 * 60 * 1000;
// react-redux-firebase config
export const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true

    // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
};

export const WebShopUrl = 'https://shop.dfiav.de/';
export const DatenschutzUrl = 'https://shop.dfiav.de/datenschutzerklaerung';
export const ImpressumUrl = 'https://shop.dfiav.de/impressum';

export const AppSupportUrl = 'https://shop.dfiav.de/support';
export const AppLicenseAgreementUrl = 'https://shop.dfiav.de/lizenzbedingungen';
export const AppVerantwortungUrl = 'https://shop.dfiav.de/verantwortliche';
